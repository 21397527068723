var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('h3',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"InfoIcon"}}),_c('span',[_vm._v(" Thông tin ")])],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Tên người sử dụng lao động"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Tên người sử dụng lao động","rules":"required|max:255","custom-messages":{
              required: "Tên người sử dụng lao động là bắt buộc",
              max: "Tên người sử dụng lao động không được quá 255 ký tự"
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataGeneral.name),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "name", $$v)},expression:"dataGeneral.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Mã số đăng ký kinh doanh/Mã số thuế/CCCD/CMND")]),_c('b-form-input',{model:{value:(_vm.dataGeneral.identityCardNumber),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "identityCardNumber", $$v)},expression:"dataGeneral.identityCardNumber"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Loại hình"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Loại hình","rules":"required","custom-messages":{required: "Loại hình là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.collectBussinessType || [],"placeholder":"Lựa chọn loại hình"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
            var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.collectBussinessType),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "collectBussinessType", $$v)},expression:"dataGeneral.collectBussinessType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Tỉnh/Thành phố")]),_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.provinceList || [],"placeholder":"Lựa chọn tỉnh/Thành phố"},on:{"input":_vm.dataDistrict},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
            var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}]),model:{value:(_vm.dataGeneral.provinceId),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "provinceId", $$v)},expression:"dataGeneral.provinceId"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Quận/Huyện")]),_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","disabled":_vm.isDisableDistrict,"options":_vm.districtList || [],"placeholder":"Lựa chọn quận/Huyện"},on:{"input":_vm.dataWard},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
            var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}]),model:{value:(_vm.dataGeneral.districtId),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "districtId", $$v)},expression:"dataGeneral.districtId"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Phường/Xã")]),_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","disabled":_vm.isDisableWard,"options":_vm.wardList || [],"placeholder":"Lựa chọn phường/xã"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
            var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}]),model:{value:(_vm.dataGeneral.wardId),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "wardId", $$v)},expression:"dataGeneral.wardId"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Địa chỉ cụ thể")]),_c('b-form-input',{model:{value:(_vm.dataGeneral.address),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "address", $$v)},expression:"dataGeneral.address"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label'),_c('b-form-checkbox',{staticClass:"mt-2",on:{"change":_vm.handleResetData},model:{value:(_vm.dataGeneral.isIndustrial),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "isIndustrial", $$v)},expression:"dataGeneral.isIndustrial"}},[_vm._v(" Có nằm trong KCN/KKT hay không? ")])],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Tên KCN/KKT")]),_c('b-form-input',{attrs:{"disabled":_vm.dataGeneral.isIndustrial ? false : true},model:{value:(_vm.dataGeneral.industrialArea),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "industrialArea", $$v)},expression:"dataGeneral.industrialArea"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Số điện thoại")]),_c('validation-provider',{attrs:{"name":"Số điện thoại","rules":"numeric","custom-messages":{numeric: "Số điện thoại không đúng định dạng",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataGeneral.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "phoneNumber", $$v)},expression:"dataGeneral.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":"email","custom-messages":{email: "email không đúng định dạng",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataGeneral.email),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "email", $$v)},expression:"dataGeneral.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Ngành sản xuất, kinh doanh chính"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngành sản xuất, kinh doanh chính","rules":"required","custom-messages":{required: "Ngành sản xuất, kinh doanh chính là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.manufacturingType || [],"placeholder":"Ngành sản xuất, kinh doanh chính"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
            var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.manufacturingType),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "manufacturingType", $$v)},expression:"dataGeneral.manufacturingType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Mặt hàng/sản phẩm dịch vụ chính")]),_c('b-form-input',{model:{value:(_vm.dataGeneral.mainProduct),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "mainProduct", $$v)},expression:"dataGeneral.mainProduct"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Quy mô lao động"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Quy mô lao động","rules":"required","custom-messages":{required: "Quy mô lao động là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.scaleWorker || [],"placeholder":"Quy mô lao động"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
            var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.scaleWorker),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "scaleWorker", $$v)},expression:"dataGeneral.scaleWorker"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Số lao động 6 tháng tới")]),_c('validation-provider',{attrs:{"name":"Số lao động 6 tháng tới","rules":"numeric","custom-messages":{numeric: "Số lao động 6 tháng tới bắt buộc là số",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataGeneral.numberWorker),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "numberWorker", $$v)},expression:"dataGeneral.numberWorker"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }