<template>
  <div>
    <b-form>
      <h3 class="d-flex align-items-center">
        <feather-icon
          size="20"
          icon="InfoIcon"
          class="mr-75"
        />
        <span>
          Thông tin
        </span>
      </h3>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Tên người sử dụng lao động<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Tên người sử dụng lao động"
              rules="required|max:255"
              :custom-messages="{
                required: `Tên người sử dụng lao động là bắt buộc`,
                max: `Tên người sử dụng lao động không được quá 255 ký tự`
              }"
            >
              <b-form-input
                v-model="dataGeneral.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Mã số đăng ký kinh doanh/Mã số thuế/CCCD/CMND</label>
            <b-form-input
              v-model="dataGeneral.identityCardNumber"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Loại hình<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Loại hình"
              rules="required"
              :custom-messages="{required: `Loại hình là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.collectBussinessType"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="collectBussinessType || []"
                placeholder="Lựa chọn loại hình"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Tỉnh/Thành phố</label>
            <v-select
              v-model="dataGeneral.provinceId"
              :reduce="item => item.id"
              label="name"
              :options="provinceList || []"
              placeholder="Lựa chọn tỉnh/Thành phố"
              @input="dataDistrict"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Quận/Huyện</label>
            <v-select
              v-model="dataGeneral.districtId"
              :reduce="item => item.id"
              label="name"
              :disabled="isDisableDistrict"
              :options="districtList || []"
              placeholder="Lựa chọn quận/Huyện"
              @input="dataWard"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Phường/Xã</label>
            <v-select
              v-model="dataGeneral.wardId"
              :reduce="item => item.id"
              label="name"
              :disabled="isDisableWard"
              :options="wardList || []"
              placeholder="Lựa chọn phường/xã"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Địa chỉ cụ thể</label>
            <b-form-input
              v-model="dataGeneral.address"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label></label>
            <b-form-checkbox
              v-model="dataGeneral.isIndustrial"
              class="mt-2"
              @change="handleResetData"
            >
              Có nằm trong KCN/KKT hay không?
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Tên KCN/KKT</label>
            <b-form-input
              v-model="dataGeneral.industrialArea"
              :disabled="dataGeneral.isIndustrial ? false : true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số điện thoại</label>
            <validation-provider
              #default="{ errors }"
              name="Số điện thoại"
              rules="numeric"
              :custom-messages="{numeric: `Số điện thoại không đúng định dạng`,}"
            >
              <b-form-input
                v-model="dataGeneral.phoneNumber"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Email</label>
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="email"
              :custom-messages="{email: `email không đúng định dạng`,}"
            >
              <b-form-input
                v-model="dataGeneral.email"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Ngành sản xuất, kinh doanh chính<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngành sản xuất, kinh doanh chính"
              rules="required"
              :custom-messages="{required: `Ngành sản xuất, kinh doanh chính là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.manufacturingType"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="manufacturingType || []"
                placeholder="Ngành sản xuất, kinh doanh chính"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Mặt hàng/sản phẩm dịch vụ chính</label>
            <b-form-input
              v-model="dataGeneral.mainProduct"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Quy mô lao động<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Quy mô lao động"
              rules="required"
              :custom-messages="{required: `Quy mô lao động là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.scaleWorker"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="scaleWorker || []"
                placeholder="Quy mô lao động"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số lao động 6 tháng tới</label>
            <validation-provider
              #default="{ errors }"
              name="Số lao động 6 tháng tới"
              rules="numeric"
              :custom-messages="{numeric: `Số lao động 6 tháng tới bắt buộc là số`,}"
            >
              <b-form-input
                v-model="dataGeneral.numberWorker"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, numeric, email, max,
} from '@validations'
import vSelect from 'vue-select'
import axios from 'axios'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import enumSelect from '../EnumSelected'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    vSelect,
    BFormCheckbox,
  },

  props: {
    dataGeneral: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      provinceList: [],
      districtList: [],
      wardList: [],
      isDisableDistrict: true,
      isDisableWard: true,
      disableProp: false,
      collectBussinessType: enumSelect.collectBussinessType,
      manufacturingType: enumSelect.manufacturingType,
      scaleWorker: enumSelect.scaleWorker,
    }
  },

  async created() {
    await this.fetchComboboProvince()
    if (this.type === 'edit') {
      await this.fetchComboboxDistrict(this.dataGeneral.provinceId)
      await this.fetchComboboxWard(this.dataGeneral.districtId)
      if (this.dataGeneral.districtId && this.dataGeneral.wardId) {
        this.isDisableDistrict = false
        this.isDisableWard = false
      }
    }
  },

  methods: {

    // Tỉnh thành phố
    async fetchComboboProvince() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_CITY)
      this.provinceList = data
    },

    // Quận huyện
    async fetchComboboxDistrict(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_DISTRICT}${a}`)
      this.districtList = data
    },

    // Xã phường
    async fetchComboboxWard(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_WARD}${a}`)
      this.wardList = data
    },

    async dataDistrict(val) {
      if (this.provinceList.length > 0 && this.dataGeneral.provinceId !== null) {
        this.isDisableDistrict = false
        this.dataGeneral.districtId = ''
        this.dataGeneral.wardId = ''
        await this.fetchComboboxDistrict(this.dataGeneral.provinceId)
      }
      if (!this.dataGeneral.provinceId) {
        this.dataGeneral.districtId = ''
        this.dataGeneral.wardId = ''
        this.isDisableDistrict = true
        this.isDisableWard = true
      }
    },

    async dataWard(val) {
      if (this.districtList.length > 0 && this.dataGeneral.districtId !== null) {
        this.isDisableWard = false
        this.dataGeneral.wardId = ''
        await this.fetchComboboxWard(this.dataGeneral.districtId)
      }
      if (!this.dataGeneral.districtId) {
        this.dataGeneral.wardId = ''
        this.isDisableWard = true
      }
    },

    handleResetData(val) {
      if (val === false) {
        this.dataGeneral.industrialArea = ''
      }
    },
  },
}
</script>

<style>

</style>
